const validation = {
  password: 'Password is required',
  new_password: 'New password is required',
  repeat_password: 'Confirm password is required',
  email: 'Email is required',
  email_format: 'Must be an email',
  token: 'No valid token provided',
  message: 'Message is required',
  first_name: 'First name is required',
  last_name: 'Last name is required',
  phone: 'Phone is required',
  vat: 'VAT is required',
  company_name: 'Company name is required',
  agree: 'You must agree to proceed',
  time: 'You have to select a time slot'
}

export default {
  locales: {
    nl: 'NL',
    en: 'EN',
    de: 'DE',
  },
  navigation: {
    spaces: 'Locations',
    contact: 'Contact',
    news: 'News',
    about: 'About us',
    support: 'FAQ',
    login: 'Operator Login',
    admin: 'Admin',
    menu: 'Menu',
    amsterdam: 'Amsterdam',
    rotterdam: 'Rotterdam',
    utrecht: 'Utrecht',
    the_hague: 'The Hague',
    frankfurt: 'Frankfurt',
    logout: 'Log out',
    popular: 'Frequently booked locations',
    discover: 'Discover',
    usp1: 'Work or have a meeting without disturbance and with complete privacy',
    usp2: 'Competitive fixed rate for all fully serviced private day offices or meeting rooms',
    usp3: 'Located at premium locations throughout the country',
    usp4: 'Just a few clicks away to book your space for today (or after 3 pm for tomorrow)',
  },
  availability: {
    full_day: 'Office space (within opening hours)',
    morning: 'Meeting room (max. 3 persons) morning (until 13:00)',
    morning_double: 'Meeting room (4-6 persons) morning (until 13:00)',
    evening: 'Meeting room (max. 3 persons) afternoon (after 13:00)',
    evening_double: 'Meeting room (4-6 persons) afternoon (after 13:00)',
  },
  home: {
    faq: {
      content: "Didn't find your answer? You could visit our {help} for more answers.",
      help: 'FAQ page',
      contact: 'contact page',
    },
  },
  footer: {
    content: 'The platform for booking affordable and accessible\noffice space for a day or meeting room for everyone!',
    redwood: '{worksnap} is part of {link}',
    privacy: 'Privacy policy',
    terms: 'Terms',
  },
  spaces: {
    workspaces_found: '{n} location found | {n} locations found',
    map: 'Search on map',
    list: 'Back to search results',
  },
  space: {
    about: 'About this location',
    time_placeholder: 'Select space type',
    costs: 'Total cost',
    subtotal: 'Subtotal',
    vat: 'VAT ({amount}%)',
    coupon: 'Coupon',
    total: 'Total',
    excl_vat: '(Excl. VAT)',
    book: 'book now',
    additional_information: 'Extra information',
    note: 'The price includes the reservation of the selected space for at least 4 hours of the day, based on the morning/evening selection',
    pay: 'Proceed to checkout',
    active_state: {
      title: 'Are you sure?',
      close: 'Close for the day',
      close_office: "Close office space for the day",
      close_meeting: "Close meeting room for the day",
      close_message: 'If this location has too many bookings, they must be canceled manually.',
      close_loading: 'Closing...',
      open: 'Open for the day',
      open_office: 'Open office space for the day',
      open_meeting: 'Open meeting room for the day',
      open_message: '',
      open_loading: 'Opening...',
    },
  },
  voucher: {
    placeholder: 'Voucher or coupon code',
    remove: 'Delete',
    apply: 'Apply',
  },
  search_bar: {
    address_placeholder: 'Enter a location',
    date_placeholder: 'Select a date',
    location_placeholder: 'Type of space',
    amentities_placeholder: 'Services',
    search: 'Search',
  },
  dates: {
    today: 'Today',
    tomorrow: 'Tomorrow',
    yesterday: 'Yesterday',
    at: 'at',
  },
  loading: 'Loading...',
  checkout: {
    billing: {
      title: 'Billing information',
      first_name_placeholder: 'First name*',
      last_name_placeholder: 'Last name*',
      email_placeholder: 'Email address*',
      phone_placeholder: 'Phone number*',
      vat_placeholder: 'VAT Number',
      company_name_placeholder: 'Company name (optional)',
      password: 'Password',
    },
    business: 'Book as a business',
    agree: {
      content: 'I agree to the {terms} of the provider of this location*',
      terms: 'terms and conditions',
    },
    save: 'Save my details for future bookings',
    payment: {
      title: 'Your payment information',
      bank_placeholder: 'Select your bank',
      issuer_placeholder: 'Select your payment method',
      note: 'Your personal information will be used to process your order, support your experience on this website and for other purposes described in our privacy policy.',
      total: 'Total',
    },
    note: 'The price includes the reservation of the selected space for at least 4 hours of the day, based on the morning/evening selection.',
    confirm: 'Confirm and pay',
    reservation: {
      title: 'Your booking',
      about: 'About this location',
    },
  },
  checkout_response: {
    title: 'Your payment has been processed successfully!',
    message: 'You will receive a confirmation email shortly.',
    close: 'Close',
  },
  contact: {
    name_placeholder: 'Your name',
    email_placeholder: 'Your email',
    message_placeholder: 'Your message',
    send: 'Send',
    sending: 'Sending...',
  },
  login: {
    title: 'Login',
    email: 'Email address',
    password: 'Password',
    login: 'Login',
    loading: 'Loading...',
    forgot: 'Forgot password',
  },
  reset: {
    title: 'Forgot password',
    email: 'Email address',
    reset: 'Reset password',
    loading: 'Loading...',
    // account: 'Already have an account?',
    login: 'Login',
  },
  forgot: {
    title: 'Reset password',
    password: 'Password',
    repeat_password: 'Confirm password',
    reset: 'Reset password',
    loading: 'Loading...',
    login: 'Login',
    email: 'Email address',
  },
  location_table: {
    name: 'Location name',
    address: 'Address',
    office_open: 'Office space open today?',
    meeting_open: 'Meeting rooms open today?',
    view: 'View',
  },
  booking_table: {
    location: 'Location',
    address: 'Address',
    start_date: 'Start date',
    end_date: 'End date',
    guest_name: 'Guest name',
    guest_email: 'Guest email',
    guest_phone: 'Phone number',
    view: 'View',
  },
  pagination: {
    next: 'Next',
    previous: 'Previous',
    page: 'Page',
  },
  validation,
  auth: {
    validation,
  },
}
