const validation = {
  password: 'Passwort ist erforderlich',
  new_password: 'Neues Passwort ist erforderlich',
  repeat_password: 'Passwort bestätigen ist erforderlich',
  email: 'E-Mail ist erforderlich',
  email_format: 'Muss eine E-Mail sein',
  token: 'Kein gültiger Token angegeben',
  message: 'Nachricht ist erforderlich',
  first_name: 'Vorname ist erforderlich',
  last_name: 'Nachname ist erforderlich',
  phone: 'Telefon ist erforderlich',
  vat: 'Umsatzsteuer ist erforderlich',
  company_name: 'Firmenname ist erforderlich',
  agree: 'Sie müssen zustimmen, um fortzufahren',
  time: 'Zeit ist erforderlich'
}
export default {
  locales: {
    nl: 'NL',
    en: 'EN',
    de: 'DE',
  },
  navigation: {
    spaces: "Standorte",
    contact: "Kontakt",
    news: "Nachrichten",
    about: "Über uns",
    support: "FAQ",
    login: "Anmelden Operator",
    admin: "Admin",
    menu: "Menü",
    amsterdam: "Amsterdam",
    rotterdam: "Rotterdam",
    utrecht: "Utrecht",
    the_hague: "Den Haag",
    frankfurt: 'Frankfurt',
    logout: "Abmelden",
    popular: "Häufig gebuchte Standorte",
    discover: "Entdecken",
    usp1: 'Arbeiten oder besprechen in ungestörter und vertraulicher Atmosphäre',
    usp2: 'Günstiger Festpreis für Ihr Tagesbüro oder Ihren Besprechungsraum',
    usp3: 'Erstklassige Standorte im ganzen Land',
    usp4: 'Mit wenigen Klicks sofort für heute, oder ab 15 Uhr für morgen, buchbar',
  },
  availability: {
    full_day: "Bürofläche (innerhalb der Öffnungszeiten)",
    morning: "Morgendlicher Beratungsraum (max. 3 Personen) (bis 13:00 Uhr)",
    morning_double: "Morgendlicher Beratungsraum (4-6 Personen) (bis 13:00 Uhr)",
    evening: "Nachmittaglicher Beratungsraum (max. 3 Personen) (nach 13:00 Uhr)",
    evening_double: "Nachmittaglicher Beratungsraum (4-6 Personen) (nach 13:00 Uhr)"
  },
  home: {
    faq: {
      content: "Haben Sie Ihre Fragen nicht gefunden? Sie könnten unsere {help} für weitere Antworten besuchen.",
      help: "Häufige Fragen (FAQs)",
      contact: "Kontaktseite"
    }
  },
  footer: {
    content: "Die Plattform zur Buchung erschwinglicher und zugänglicher\nBüroplatze für einen Tag oder eines Besprechungsraums für jedermann!",
    redwood: "{worksnap} ist Teil der {link}",
    privacy: "Datenschutzrichtlinie",
    terms: "Bedingungen"
  },
  spaces: {
    workspaces_found: "{n} Standorte gefunden | {n} Standorte gefunden",
    map: "Auf Karte suchen",
    list: "Zurück zu den Suchergebnissen"
  },
  space: {
    about: "Über diesen Standort",
    time_placeholder: "Wählen Sie den Raumtyp aus",
    costs: "Gesamtkosten",
    subtotal: "Zwischensumme",
    vat: "Umsatzsteuer ({amount}%)",
    coupon: "Gutschein",
    total: "Gesamt",
    excl_vat: "(Ohne Umsatzsteuer)",
    book: "Jetzt buchen",
    additional_information: "Zusätzliche Informationen",
    note: "Der Preis beinhaltet die Reservierung des ausgewählten Raums für mindestens 4 Stunden pro Tag, basierend auf der Auswahl von Morgen/Abend.",
    pay: "Zur Kasse gehen",
    active_state: {
      title: "Sind Sie sicher?",
      close: "Für den Tag schließen",
      close_office: "Bürofläche für den Tag schließen",
      close_meeting: "Beratungsraum für den Tag schließen",
      close_message: "Wenn dieser Standort zu viele Buchungen hat, müssen sie manuell storniert werden.",
      close_loading: "Schließen...",
      open: "Für den Tag öffnen",
      open_office: "Bürofläche für den Tag öffnen",
      open_meeting: "Beratungsraum für den Tag öffnen",
      open_message: "",
      open_loading: "Öffnen..."
    }
  },
  voucher: {
    placeholder: "Gutschein- oder Couponcode",
    remove: "Löschen",
    apply: "Anwenden"
  },
  search_bar: {
    address_placeholder: "Standort eingeben",
    date_placeholder: "Datum auswählen",
    location_placeholder: "Raumtyp",
    amentities_placeholder: "Dienstleistungen",
    search: "Suchen"
  },
  dates: {
    today: "Heute",
    tomorrow: "Morgen",
    yesterday: "Gestern",
    at: "um "
  },
  loading: "Laden...",
  checkout: {
    billing: {
      title: "Abrechnungsinformationen",
      first_name_placeholder: "Vorname*",
      last_name_placeholder: "Nachname*",
      email_placeholder: "E-Mail-Adresse*",
      phone_placeholder: "Telefonnummer*",
      vat_placeholder: "Umsatzsteuer-Nummer",
      company_name_placeholder: "Firmenname (Optional)",
      password: "Passwort"
    },
    business: "Als Unternehmen buchen",
    agree: {
      content: "Ich stimme den {terms} des Anbieters dieses Standorts zu*",
      terms: "Allgemeine Geschäftsbedingungen"
    },
    save: "Meine Daten für zukünftige Buchungen speichern",
    payment: {
      title: "Ihre Zahlungsinformationen",
      bank_placeholder: "Wählen Sie Ihre Bank aus",
      issuer_placeholder: "Wählen Sie Ihre Zahlungsmethode aus",
      note: "Ihre persönlichen Informationen werden verwendet, um Ihre Bestellung zu bearbeiten, Ihre Erfahrung auf dieser Website zu unterstützen und für andere Zwecke gemäß unserer Datenschutzerklärung beschrieben.",
      total: "Gesamt"
    },
    note: "Der Preis beinhaltet die Reservierung des ausgewählten Raums für mindestens 4 Stunden pro Tag, basierend auf der Auswahl von Morgen/Mittag.",
    confirm: "Bestätigen und bezahlen",
    reservation: {
      title: "Ihre Buchung",
      about: "Über diesen Standort"
    }
  },
  checkout_response: {
    title: "Ihre Zahlung wurde erfolgreich verarbeitet!",
    message: "Sie erhalten in Kürze eine Bestätigungs-E-Mail.",
    close: "Schließen"
  },
  contact: {
    name_placeholder: "Ihr Name",
    email_placeholder: "Ihre E-Mail",
    message_placeholder: "Ihre Nachricht",
    send: "Senden",
    sending: "Senden..."
  },
  login: {
    title: "Anmelden",
    email: "E-Mail-Adresse",
    password: "Passwort",
    login: "Anmelden",
    loading: "Laden...",
    forgot: "Passwort vergessen"
  },
  reset: {
    title: "Passwort vergessen",
    email: "E-Mail-Adresse",
    reset: "Passwort zurücksetzen",
    loading: "Laden...",
    login: "Anmelden"
  },
  forgot: {
    title: "Passwort zurücksetzen",
    password: "Passwort",
    repeat_password: "Passwort bestätigen",
    reset: "Passwort zurücksetzen",
    loading: "Laden...",
    login: "Anmelden",
    email: "E-Mail-Adresse"
  },
  location_table: {
    name: 'Standortname',
    address: 'Adresse',
    office_open: 'Bürofläche für den Tag geöffnet?',
    meeting_open: 'Beratungsraum für den Tag geöffnet?',
    view: 'Anzeigen',
  },
  booking_table: {
    location: "Standort",
    address: "Adresse",
    start_date: "Startdatum",
    end_date: "Enddatum",
    guest_name: "Gastname",
    guest_email: "Gast-E-Mail",
    guest_phone: "Telefonnummer",
    view: "Anzeigen"
  },
  pagination: {
    next: "Weiter",
    previous: "Zurück",
    page: "Seite"
  },
  validation,
  auth: {
    validation,
  },
}
